import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import EmptySpaceSeperator from "../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import BlogItems from "../components/Blog/BlogItems/blogItems"
import decode from "unescape"


const singleCategory = (props) => {
  const { category, blogs } = props.data


  return (
    <Layout locale={props.pageContext.locale} path={props.path}>
      <SEO title={decode(category.name)}
           lang={props.pageContext.locale}
           description={category.yoast_meta.yoast_wpseo_metadesc}
      />
      <Container>
        <Row>
          <Col md={12}>
            <EmptySpaceSeperator/>
            <div className="border-bottom">
              <span className="subheader colorMidGray">Category</span>
              <h1 className="h2TitleStyled colorDarkGray">{decode(category.name)}</h1>
              <EmptySpaceSeperator pixels={40}/>

            </div>
          </Col>
        </Row>
        <EmptySpaceSeperator pixels={40}/>

        <BlogItems className="PostShadowed p-6-px" masonry={true} shadow={true} items={blogs.edges}/>

      </Container>
    </Layout>
  )
}
export const singleCategoryQuery = graphql`
    query($id: Int!,$locale: String!) {
        category:   wordpressCategory(wordpress_id: {eq: $id}) {
            polylang_current_lang
            slug
            path
            name
            yoast_meta {
                yoast_wpseo_metadesc
                yoast_wpseo_title
            }
        }
        blogs: allWordpressPost(filter: {categories: {elemMatch: {wordpress_id: {in: [$id]}}}, polylang_current_lang: {eq: $locale}}) {
            edges {
                node {
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                        alt_text
                    }
                    excerpt
                    categories {
                        name
                        slug
                        path
                    }
                    id
                    title
                    slug
                    polylang_current_lang
                    modified(formatString:"MMMM YYYY")
                    tags {
                        name
                    }
                }
            }
        }

    }
`

export default singleCategory
